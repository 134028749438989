<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row" id="topo_pagina">
      <div class="md-toolbar-section-start" v-if="tokenConsultaSuperbox == ''">
        <h3 class="md-title -route">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <!-- <div class="md-collapse">
          <md-list>
            <md-list-item>
              <md-button class="md-simple md-primary md-sm" @click.native="perguntaSair()">
                <i class="fas fa-sign-out-alt"></i>&nbsp;Sair
              </md-button>
            </md-list-item>
          </md-list>
        </div> -->
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { logout } from "@/helpers";
import { sessionStore } from "@/stores/session";
import { mapActions, mapWritableState } from "pinia";

export default {
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    perguntaSair() {
      logout.perguntaSair();
    },
  },
  computed: {
    ...mapWritableState(sessionStore, {
      tokenConsultaSuperbox: "tokenConsultaSuperbox",
    }),
  },
};
</script>
